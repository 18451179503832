:root {
    --min-viewport: 320;
    --max-viewport: 1440;

    --min-bg8-xxxl: 72;
    --max-bg8-xxxl: 160;
    --bg8-xxxl: clamp(calc(var(--min-bg8-xxxl) * 1px),
            calc(var(--min-bg8-xxxl) * 1px + (var(--max-bg8-xxxl) - var(--min-bg8-xxxl)) * (100vw - var(--min-viewport) * 1px) / (var(--max-viewport) - var(--min-viewport))),
            calc(var(--max-bg8-xxxl) * 1px));

    --min-bg8-xxl: 36;
    --max-bg8-xxl: 64;
    --bg8-xxl: clamp(calc(var(--min-bg8-xxl) * 1px),
            calc(var(--min-bg8-xxl) * 1px + (var(--max-bg8-xxl) - var(--min-bg8-xxl)) * (100vw - var(--min-viewport) * 1px) / (var(--max-viewport) - var(--min-viewport))),
            calc(var(--max-bg8-xxl) * 1px));

    --min-bg8-xl: 32;
    --max-bg8-xl: 48;
    --bg8-xl: clamp(calc(var(--min-bg8-xl) * 1px),
            calc(var(--min-bg8-xl) * 1px + (var(--max-bg8-xl) - var(--min-bg8-xl)) * (100vw - var(--min-viewport) * 1px) / (var(--max-viewport) - var(--min-viewport))),
            calc(var(--max-bg8-xl) * 1px));

    --min-bg8-l: 24;
    --max-bg8-l: 36;
    --bg8-l: clamp(calc(var(--min-bg8-l) * 1px),
            calc(var(--min-bg8-l) * 1px + (var(--max-bg8-l) - var(--min-bg8-l)) * (100vw - var(--min-viewport) * 1px) / (var(--max-viewport) - var(--min-viewport))),
            calc(var(--max-bg8-l) * 1px));

    --min-bg8-m: 18;
    --max-bg8-m: 24;
    --bg8-m: clamp(calc(var(--min-bg8-m) * 1px),
            calc(var(--min-bg8-m) * 1px + (var(--max-bg8-m) - var(--min-bg8-m)) * (100vw - var(--min-viewport) * 1px) / (var(--max-viewport) - var(--min-viewport))),
            calc(var(--max-bg8-m) * 1px));

    --min-bg8-s: 16;
    --max-bg8-s: 18;
    --bg8-s: clamp(calc(var(--min-bg8-s) * 1px),
            calc(var(--min-bg8-s) * 1px + (var(--max-bg8-s) - var(--min-bg8-s)) * (100vw - var(--min-viewport) * 1px) / (var(--max-viewport) - var(--min-viewport))),
            calc(var(--max-bg8-s) * 1px));

    --min-bg8-xs: 16;
    --max-bg8-xs: 16;
    --bg8-xs: clamp(calc(var(--min-bg8-xs) * 1px),
            calc(var(--min-bg8-xs) * 1px + (var(--max-bg8-xs) - var(--min-bg8-xs)) * (100vw - var(--min-viewport) * 1px) / (var(--max-viewport) - var(--min-viewport))),
            calc(var(--max-bg8-xs) * 1px));

    --min-bg8-xxs: 12;
    --max-bg8-xxs: 16;
    --bg8-xxs: clamp(calc(var(--min-bg8-xxs) * 1px),
            calc(var(--min-bg8-xxs) * 1px + (var(--max-bg8-xxs) - var(--min-bg8-xxs)) * (100vw - var(--min-viewport) * 1px) / (var(--max-viewport) - var(--min-viewport))),
            calc(var(--max-bg8-xxs) * 1px));

    --min-bg8-upload-card: 12;
    --max-bg8-upload-card: 24;
    --bg8-upload-card: clamp(calc(var(--min-bg8-upload-card) * 1px),
            calc(var(--min-bg8-upload-card) * 1px + (var(--max-bg8-upload-card) - var(--min-bg8-upload-card)) * (100vw - var(--min-viewport) * 1px) / (var(--max-viewport) - var(--min-viewport))),
            calc(var(--max-bg8-upload-card) * 1px));

    --min-bg8-gap-sm: 4;
    --max-bg8-gap-sm: 16;
    --bg8-gap-sm: clamp(calc(var(--min-bg8-gap-sm) * 1px),
            calc(var(--min-bg8-gap-sm) * 1px + (var(--max-bg8-gap-sm) - var(--min-bg8-gap-sm)) * (100vw - var(--min-viewport) * 1px) / (var(--max-viewport) - var(--min-viewport))),
            calc(var(--max-bg8-gap-sm) * 1px));

    --min-bg8-gap-lg: 16;
    --max-bg8-gap-lg: 32;
    --bg8-gap-lg: clamp(calc(var(--min-bg8-gap-lg) * 1px),
            calc(var(--min-bg8-gap-lg) * 1px + (var(--max-bg8-gap-lg) - var(--min-bg8-gap-lg)) * (100vw - var(--min-viewport) * 1px) / (var(--max-viewport) - var(--min-viewport))),
            calc(var(--max-bg8-gap-lg) * 1px));


    --bg8-base: 16px;

    --bg8-headings: 'Poppins';
    --bg8-body: 'Montserrat';

    // --nmm-dark: #321836;
    --nmm-dark: #281739;
    --nmm-light: #fdbf63;
    --nmm-lighter: #f6e0bf;
    --nmm-lightest: #fff9f0;
    --nmm-dark-deep: #19071d;
    --nmm-light-deep: #e8b466;
    --nmm-danger-light: #ffeaea;
    --nmm-grey-light: #fffaf5;
    --white: #ffffff;
    --black: #000000;
}

$white: #ffffff;
$black: #000000;

$theme-colors: (
    "light": #fffaf5,
    // "dark":       #321836,
    "dark": #281739,
    "primary": #ffcc4b,
    "secondary": #9a6053,
    "info": #d28273,
    "accent1": #f8a2c5,
    "accent2": #76ccf4,
    "accent3": #21c0e9,
    "success": #59ca00,
    "warning": #ffcc7d,
    "danger": #b80019,
);

$border-radius: 4px;
$border-radius-sm: 8px;
$border-radius-lg: var(--bg8-s);
$border-radius-xl: var(--bg8-m);
$border-radius-xxl: var(--bg8-l);

$card-height: 100%;

$toast-background-color: $white;
$toast-color: $black;

$dropdown-link-active-bg: var(--nmm-dark);

$accordion-button-active-bg: var(--nmm-light);
$accordion-button-active-color: var(--nmm-dark);

$list-group-active-bg: var(--nmm-light);
$list-group-active-color: var(--nmm-dark);
$list-group-hover-bg: var(--nmm-light);