@import 'variables';
@import 'node_modules/bootstrap/scss/bootstrap';

:root {
    --min-viewport: 320;
    --max-viewport: 1440;

    --min-bg8-xxxl: 72;
    --max-bg8-xxxl: 160;
    --bg8-xxxl: clamp(calc(var(--min-bg8-xxxl) * 1px),
            calc(var(--min-bg8-xxxl) * 1px + (var(--max-bg8-xxxl) - var(--min-bg8-xxxl)) * (100vw - var(--min-viewport) * 1px) / (var(--max-viewport) - var(--min-viewport))),
            calc(var(--max-bg8-xxxl) * 1px));

    --min-bg8-xxl: 36;
    --max-bg8-xxl: 64;
    --bg8-xxl: clamp(calc(var(--min-bg8-xxl) * 1px),
            calc(var(--min-bg8-xxl) * 1px + (var(--max-bg8-xxl) - var(--min-bg8-xxl)) * (100vw - var(--min-viewport) * 1px) / (var(--max-viewport) - var(--min-viewport))),
            calc(var(--max-bg8-xxl) * 1px));

    --min-bg8-xl: 32;
    --max-bg8-xl: 48;
    --bg8-xl: clamp(calc(var(--min-bg8-xl) * 1px),
            calc(var(--min-bg8-xl) * 1px + (var(--max-bg8-xl) - var(--min-bg8-xl)) * (100vw - var(--min-viewport) * 1px) / (var(--max-viewport) - var(--min-viewport))),
            calc(var(--max-bg8-xl) * 1px));

    --min-bg8-l: 24;
    --max-bg8-l: 36;
    --bg8-l: clamp(calc(var(--min-bg8-l) * 1px),
            calc(var(--min-bg8-l) * 1px + (var(--max-bg8-l) - var(--min-bg8-l)) * (100vw - var(--min-viewport) * 1px) / (var(--max-viewport) - var(--min-viewport))),
            calc(var(--max-bg8-l) * 1px));

    --min-bg8-m: 18;
    --max-bg8-m: 24;
    --bg8-m: clamp(calc(var(--min-bg8-m) * 1px),
            calc(var(--min-bg8-m) * 1px + (var(--max-bg8-m) - var(--min-bg8-m)) * (100vw - var(--min-viewport) * 1px) / (var(--max-viewport) - var(--min-viewport))),
            calc(var(--max-bg8-m) * 1px));

    --min-bg8-s: 16;
    --max-bg8-s: 18;
    --bg8-s: clamp(calc(var(--min-bg8-s) * 1px),
            calc(var(--min-bg8-s) * 1px + (var(--max-bg8-s) - var(--min-bg8-s)) * (100vw - var(--min-viewport) * 1px) / (var(--max-viewport) - var(--min-viewport))),
            calc(var(--max-bg8-s) * 1px));

    --min-bg8-xs: 16;
    --max-bg8-xs: 16;
    --bg8-xs: clamp(calc(var(--min-bg8-xs) * 1px),
            calc(var(--min-bg8-xs) * 1px + (var(--max-bg8-xs) - var(--min-bg8-xs)) * (100vw - var(--min-viewport) * 1px) / (var(--max-viewport) - var(--min-viewport))),
            calc(var(--max-bg8-xs) * 1px));

    --min-bg8-xxs: 12;
    --max-bg8-xxs: 16;
    --bg8-xxs: clamp(calc(var(--min-bg8-xxs) * 1px),
            calc(var(--min-bg8-xxs) * 1px + (var(--max-bg8-xxs) - var(--min-bg8-xxs)) * (100vw - var(--min-viewport) * 1px) / (var(--max-viewport) - var(--min-viewport))),
            calc(var(--max-bg8-xxs) * 1px));

    --min-bg8-upload-card: 12;
    --max-bg8-upload-card: 24;
    --bg8-upload-card: clamp(calc(var(--min-bg8-upload-card) * 1px),
            calc(var(--min-bg8-upload-card) * 1px + (var(--max-bg8-upload-card) - var(--min-bg8-upload-card)) * (100vw - var(--min-viewport) * 1px) / (var(--max-viewport) - var(--min-viewport))),
            calc(var(--max-bg8-upload-card) * 1px));

    --min-bg8-gap-sm: 4;
    --max-bg8-gap-sm: 16;
    --bg8-gap-sm: clamp(calc(var(--min-bg8-gap-sm) * 1px),
            calc(var(--min-bg8-gap-sm) * 1px + (var(--max-bg8-gap-sm) - var(--min-bg8-gap-sm)) * (100vw - var(--min-viewport) * 1px) / (var(--max-viewport) - var(--min-viewport))),
            calc(var(--max-bg8-gap-sm) * 1px));

    --min-bg8-gap-lg: 16;
    --max-bg8-gap-lg: 32;
    --bg8-gap-lg: clamp(calc(var(--min-bg8-gap-lg) * 1px),
            calc(var(--min-bg8-gap-lg) * 1px + (var(--max-bg8-gap-lg) - var(--min-bg8-gap-lg)) * (100vw - var(--min-viewport) * 1px) / (var(--max-viewport) - var(--min-viewport))),
            calc(var(--max-bg8-gap-lg) * 1px));


    --bg8-base: 16px;

    --bg8-headings: 'Poppins';
    --bg8-body: 'Montserrat';

    // --nmm-dark: #321836;
    --nmm-dark: #281739;
    --nmm-light: #fdbf63;
    --nmm-lighter: #f6e0bf;
    --nmm-lightest: #fff9f0;
    --nmm-dark-deep: #19071d;
    --nmm-light-deep: #e8b466;
    --nmm-danger-light: #ffeaea;
    --nmm-grey-light: #fffaf5;
    --white: #ffffff;
    --black: #000000;
}

$white: #ffffff;
$black: #000000;

$theme-colors: (
    "light": #fffaf5,
    // "dark":       #321836,
    "dark": #281739,
    "primary": #ffcc4b,
    "secondary": #9a6053,
    "info": #d28273,
    "accent1": #f8a2c5,
    "accent2": #76ccf4,
    "accent3": #21c0e9,
    "success": #59ca00,
    "warning": #ffcc7d,
    "danger": #b80019,
);

$border-radius: 4px;
$border-radius-sm: 8px;
$border-radius-lg: var(--bg8-s);
$border-radius-xl: var(--bg8-m);
$border-radius-xxl: var(--bg8-l);

$card-height: 100%;

$toast-background-color: $white;
$toast-color: $black;

$dropdown-link-active-bg: var(--nmm-dark);

$accordion-button-active-bg: var(--nmm-light);
$accordion-button-active-color: var(--nmm-dark);

$list-group-active-bg: var(--nmm-light);
$list-group-active-color: var(--nmm-dark);
$list-group-hover-bg: var(--nmm-light);

.cta-button-set {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: var(--bg8-xl);
  gap: var(--bg8-s);

  .cta-btn {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--bg8-xs);
    padding: var(--bg8-s);
    background-color: var(--nmm-lightest);
    color: var(--nmm-dark);
    text-decoration: none;
    font-weight: 500;
    border-radius: $border-radius;
    box-shadow: 0 6px 12px 0 rgba(73, 40, 106, 0.5);

    &:hover {
      background-color: var(--white);
    }

    .cta-icon {
      font-size: var(--bg8-m);
    }

    .cta-label {
      text-decoration: none;
    }
  }

  @media (min-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--bg8-xl);

    .cta-btn {
      flex-direction: column;
      padding: var(--bg8-m);
      max-width: 150px;

      .cta-icon {
        font-size: var(--bg8-l);
      }

      .cta-label {
        text-align: center;
      }
    }
  }
}


.action-block {
  background-color: var(--nmm-lightest);
  padding: var(--bg8-m);
  border-radius: var(--bs-border-radius);
  color: var(--nmm-dark);
  box-shadow: 0 6px 12px 0 rgba(73, 40, 106, 0.5);
}
.card {
    text-decoration: none;
    transition: box-shadow .1s ease-out, border-color .1s ease-out;

    &.selected,
    &:hover {
        border-color: $black;
        box-shadow: 0 6px 20px 0 rgba(0,0,0,.5);
    }

    .card-title {
        display: flex;
        justify-content: flex-end;
    }
    .card-body {
        min-height: 72px;
    }
    .card-footer {
        height: 100%;
    }
}

.card-img-overlay {
    transition: opacity .3s ease-in-out;
    margin-bottom: 64px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,.85) 60%);
    text-decoration: none;
    
    &:hover { 
        opacity: .1;
        cursor: pointer;
    }

    &.transparent {
        background: transparent;
    }

    .video-overlay {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 75%;

        .video-icon {
            width: 100px;
            height: 100px;
            opacity: .75;
        }
    }

}


.gallery-grid {
    .media-item {
        // aspect-ratio: 1;
    }
    .card {
        border: 0;
        
        .card-img-top {
            aspect-ratio: 1;
            object-fit: cover;
            min-height: auto;
        }
    }

    .card-img-overlay {
        margin-bottom: 0;
        padding: 0;
        
        .card-title {
            position: absolute;
            top: 8px;
            right: 8px;

            @media(max-width: 480px) {
                display: none;
                top: 4px;
                right: 4px;
            }
        }
        .video-overlay {
            height: 100%;

            .video-icon {
                max-width: 30%;
                height: auto;
            }
        }
    }
}





.is-pending .card-img-overlay:hover {
    opacity: 1;
}

.card-img-top {
    min-height:100px;
}

.action-link {
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    overflow: hidden;
    width: fit-content;
    cursor: pointer;

    .action-icon {
        padding: 8px;

        &.has-favourites,
        &.favourite {
            color: var(--bs-danger);
        }
    }

    .action-label:not(.no-hide) {
        max-width: 0;
        white-space: nowrap;
        transition: max-width .3s ease-in-out, padding .3s ease-in-out;
        line-height: 1em;
        opacity: 0;
    }

    &:hover {
        .action-label:not(.no-hide) {
            max-width: 200px;
            padding-right: 8px;
            opacity: 1;
        }
    }
}


.gallery-count {
    font-size: .75em;
}

.masonry-item {
    margin-bottom: 24px;
}

.stats-row {
    display: flex;
    justify-content: flex-start;
    gap: 16px;

    .count-icon {
        min-width: 50px;
    }

    .count-value {
        min-width: 100px;
        text-align: right;
    }
}
.carousel-item {
    > .ratio {
        background-color: transparent !important;
    }
}
@include color-mode(dark) {
  a {
    color: var(--bs-body-color);
  }

  .btn-primary {
    color: var(--bs-body-bg);
  }

  .card {
    &.selected {
      border-color: $black;
      box-shadow: 0 6px 20px 0 rgba(204, 204, 204, 0.5);
    }
  }

  .btn-outline-dark {
    color: var(--bs-body-color);
    border-color: var(--bs-border-color);

    &:hover,
    &:active,
    &.active,
    &:focus {
      background-color: var(--bs-body-color);
      color: var(--bs-body-bg);
    }
  }

  .media-preview {
    .source-focal-point {
      box-shadow: 0 0 0 5000px rgba(var(--bs-body-bg-rgb), 0.7);
    }
  }

  .social-links {
    a {
      color: var(--bs-body-color);
    }
  }
}
.debugbar {
  max-height: 50vh;
  z-index: 10;
  // background-color: $white;
  
  .debugbar-detail {
    margin-top: 8px;
    max-height: 45vh;
    overflow-y: scroll;
  }
}

    .dropzone {
        background-color: var(--nmm-lightest);
        box-shadow: 0 6px 12px 0 rgba(73, 40, 106, 0.75);
        color: var(--nmm-dark);
        border-radius: var(--bs-border-radius);
        padding: var(--bg8-sm);
        border: 0;
        opacity: .90;
        // transition: opacity .2s ease-in-out;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        &:hover,
        &:active,
        &:focus {
            opacity: .95;
        }

        .dz-message {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            gap: 12px;

            .dz-message-icons {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                gap: 12px;
                font-size: 1.25em;
            }
        }

        .dz-preview {
            margin: 12px;

            &.dz-image-preview {
                background-color: transparent;
            }

            .dz-image {
                border-radius: var(--bs-border-radius);
            }

            &:hover {
                .dz-details {
                    opacity: 0;
                }

                .dz-image {
                    img {
                        filter: blur(0);
                    }
                }
            }

            .dz-filename {
                display: none;
            }

            .dz-progress {
                .dz-upload {
                    background: linear-gradient(to bottom, var(--nmm-light-deep), var(--nmm-light));
                }
            }
        }
    }
.form-label {
    margin-bottom: 0;
    width: 100%;
    // background-color: var(--bs-gray-100);
    padding: 0.375rem 0.75rem;
    border-top-left-radius: var(--bs-border-radius);
    border-top-right-radius: var(--bs-border-radius);
    border: 1px solid var(--bs-border-color);
    border-bottom: 0;
}

.form-label+.form-control,
.form-label+.form-select,
.form-label+.input-group-text {
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    &:focus {
        outline: 0;
        border-color: var(--bs-gray-500);
        box-shadow: none;
    }
}

.form-text {
    padding: 0.375rem 0.75rem;
}

.form-check-lg {
    margin-bottom: 1em;

    &.form-switch {
        padding-left: 3.7em;
    }

    .form-check-input {
        font-size: 1.5em;
    }

    .form-check-label {
        margin-top: 0.35em;
    }
}

.form-floating {
    >label {
        font-size: .85em;
    }
}

input[type="file"]::file-selector-button {
    background-color: transparent;
}

.muid {
    width: 200px;
}

fieldset {
    padding: 1rem;
    margin: 1.5rem 0;
    border: 1px solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);

    legend {
        margin: -1.75rem 0;
        background-color: var(--bs-body-bg);
        padding: 0 0.75rem;
        width: auto;
        font-size: 1.1rem;
        font-weight: 200;
    }
}

.copy-input-btn {
    min-width: 110px;
    transition: background-color .3s ease-in-out;
}

.centered-textarea {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-height: 150px;
    width: 100%;
    padding: 10px;
    background-color: var(--white);
    border: 1px solid var(--bs-border-color);
    border-radius: 5px;
    margin: 0 auto;
    font-size: 1.5em;
    font-weight: 100;
    // font-style: italic;
}

.centered-textarea:empty::before {
    content: attr(data-placeholder);
    color: #aaa;
    pointer-events: none;
}

.upload-wrapper {
    max-height: 0;
    /* Initially hidden */
    overflow: hidden;
    /* Prevent content overflow */
    transition: max-height 0.5s ease-in-out;
    /* Smooth slide-down transition */
}

.upload-wrapper.is-visible {
    max-height: 200px;
    /* Set this to the approximate height of the wrapper when visible */
}
.gallery-grid {
    display: grid;
    gap: 2px;
    margin-bottom: var(--bg8-xl);

    .media-item {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .audio-item {
        border: 1px solid #efefef;
    }

    .gallery-select-toggle {
        &.active {
            background-color: var(--nmm-light);
            padding-left: 8px;
        }
    }

    .media-item.selected {
        // opacity: 0.8;
    }

    .media-item .select-overlay {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        padding: 1rem;
        display: none;
    }

    .media-item.selected .select-overlay {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        font-size: 1.5rem;
        color: var(--nmm-light);
        background-color: rgba(0, 0, 0, 0.5);
        border: 3px solid var(--nmm-light);
    }



}

.grid-zoom-slider {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1000;
    background: var(--bs-body-bg);
    padding: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;

    .slider-wrapper {
        width: 100%;

        .slider {
            // appearance: none;
            // -webkit-appearance: none;
            width: 100%;
            height: 8px;
            border-radius: 8px;
            background-color: var(--nmm-lightest);
            outline: none;
            margin-top: 6px;
            margin-bottom: 9px;
            accent-color: var(--bs-body-color);

            &::-moz-range-progress {
                background-color: var(--nmm-lightest);
            }

            &::-moz-range-track {
                background-color: var(--nmm-lightest);
            }

            /* IE*/
            &::-ms-fill-lower {
                background-color: var(--nmm-lightest);
            }

            &::-ms-fill-upper {
                background-color: var(--nmm-lightest);
            }
        }

        .slider::-webkit-slider-thumb {
            appearance: none;
            -webkit-appearance: none;
            width: 32px;
            height: 32px;
            border-radius: 16px;
            background-color: var(--bs-body-color);
            cursor: pointer;
            margin-top: -12px;
        }

        .slider::-moz-range-thumb {
            width: 32px;
            height: 32px;
            border-radius: 16px;
            background-color: var(--bs-body-color);
            cursor: pointer;
            margin-top: -12px;
        }
    }
}



.upload-card {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    aspect-ratio: 1;
    gap: var(--bg8-gap-sm);
    padding: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    text-align: center;
    // box-shadow: 0 6px 12px 0 rgba(73, 40, 106, 0.75);
    background-color: var(--bs-body-bg);
    color: var(--bs-body-color);

    svg {
        width: 50%;
        height: 50%;
    }

    .upload-card-icon {
        // font-size: var(--bg8-xxl);
        transition: transform .2s ease-in-out;
    }

    &:hover {
        opacity: .95;
        box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.25);

        .upload-card-icon {
            transform: scale(1.05);
        }
    }

    .upload-card-label {
        font-weight: 400;
        margin-bottom: 0;
        text-transform: uppercase;
        font-size: var(--bg8-upload-card);
        line-height: 1.2em;
    }

    @media(max-width:640px) {
        gap: 0;

        .upload-card-label {
            display: none;
        }

    }
}




// main.selectmode {
//     background-color: var(--white);
// }
.guestbook-message {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    gap: var(--bg8-gap-sm);
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: var(--nmm-dark-deep);
    // font-style: italic;
    overflow: hidden;
    padding: var(--bg8-s);
    background-color: rgba(255, 255, 255, .9);
    text-align: center;
    text-overflow: ellipsis;
    font-size: var(--bg8-xxs);

    .sender {
        // padding-top: 75%;
        text-shadow: 0 0 8px var(--white);
    }
}

.custom-caption {
    padding: var(--bg8-xl);
    background-color: rgba(255, 255, 255, .9);
    border-radius: 20px;
    text-align: center;
    min-width: 300px;
    min-height: 200px;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // justify-content: center;
    max-height: 75vh;
    overflow: auto;
}

.lg-components {
    top: 0;
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.lg-sub-html .custom-caption p {
    font-size: var(--bg8-m);
    color: var(--nmm-dark-deep);
    text-align: center;
    margin: 0;
    // font-style: italic;
    font-weight: 100;
    line-height: 1.6em;
}

.lg-sub-html .custom-caption .sender {
    color: var(--nmm-dark-deep);
    font-size: var(--bg8-s);
    // font-style: italic;
    font-weight: 100;
    margin-top: 1.5rem;

}

.lg-content {
    bottom: 0 !important;
}

.download-guestbook {
    cursor: pointer;
}
.header-logo {
    width: 200px;
    color: var(--nmm-dark);
}

#navbar_top {
    background: linear-gradient(180deg, var(--nmm-light) 40%, var(--nmm-light-deep) 100%);

}

.navbar {
    a.nav-link {
        color: var(--nmm-dark);
    }

    .btn-link {
        color: var(--nmm-dark);
    }
}
.download-pdf-link {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 1rem;
    width: fit-content;
}
.landing-wrapper {
    width: 100%;
    min-height: calc(100vh - 82px);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;

    .landing-wrapper-inner {
        width: 100%;
        min-height: calc(100vh - 82px);
        margin: 0px -12px;
        padding-top: 64px;
        // background-image: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
    }

    .inset {
        max-width: 800px;
    }
    .inset-sm {
        max-width: 600px;
        margin: 0 auto;
    }

    .action-tiles-wrapper {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 32px;
    }

    .action-tile {
        width: 100%;
        max-width: 200px;
        gap: 16px;
        border-radius: 8px;
        padding: var(--bg8-l);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        opacity: .85;
        transition: opacity .2s ease-in-out;
        text-align: center;
        box-shadow: 0 6px 12px 0 rgba(73, 40, 106, 0.75);
        background-color: var(--nmm-lightest);
        color: var(--nmm-dark);

        .action-icon {
            font-size: var(--bg8-xxl);
            margin-bottom: var(--bg8-s);
            transition: transform .2s ease-in-out;
        }

        &:hover {
            opacity: .95;
            box-shadow: 0 6px 18px 0 rgba(73, 40, 106, 0.95);

            .action-icon {
                transform: scale(1.05);
            }
        }
        
        .action-label {
            font-weight: 400;
        }
    }
}




.has-no-bg {
    a {
        color: var(--bs-body-color);
    }
}

// .has-image-bg,
// .has-video-bg {
//     a {
//         color: var(--text-light);
//     }
// }


@media(max-width:640px) {
    .landing-wrapper {
        .action-tiles-wrapper {
            gap: 16px;
        }

        .action-tile {
            flex-direction: row;
            max-width: 100%;

            .action-icon {
                font-size: var(--bg8-l);
                margin-bottom: 0;
            }
            .action-label {
                margin-bottom: 0;
            }
        }
    }
}

.lg-outer {
    .lg-thumb-item {
        &.active, 
        &:hover {
            border-color: var(--nmm-light);
        }
    }
}

.lg-toolbar {
    .lg-icon {
        color: var(--nmm-light);
        font-size: 2em;

        svg {
            height: 24px;
            vertical-align: baseline;
        }
    }
}

.lg-progress-bar .lg-progress {
    background-color: var(--nmm-light);
}

.lg-outer .lg-video-cont .lg-object.lg-video-poster {
    width: auto !important;
    height: auto !important;
}
.login-wrapper {
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(180deg, var(--nmm-dark) 40%, var(--nmm-dark-deep) 100%);

    .logo {
        fill: currentColor;
        max-width: 250px;
        margin-left: auto;

        @media(max-width: 767px) {
            margin-left: 0;
        }
    }
}
/* OFFSET FOR FIXED DEBUG BAR */
main.dev {
    padding-bottom: 64px;
}

a {
    color: #222;

    &:hover {
        cursor: pointer;
    }
}

ol > li {
    margin-bottom: .5em;
}
[data-bs-toggle="modal"] {
    cursor: pointer;
}

.modal {
    background-color: rgba(0, 0, 0, .9);
    border-radius: var(--bs-border-radius);
}

.lightbox {
    border-radius: 0;

    .btn-close {
        color: var(--nmm-light);
        opacity: 1;
        font-size: 1.5em;
    }
}

.media-settings-modal {
    .action-icon {
        min-width: 34px;
        margin-right: 4px;
    }

}

.media-preview {
    position: relative;
    cursor: crosshair;
    overflow: hidden;

    .source-focal-point {
        position: absolute;
        width: calc(100% - 24px);
        top: calc(var(--focus-y) - 40px);
        height: 80px;
        background-color: transparent;
        pointer-events: none;
        border: 1px solid var(--bs-body-bg-rgb);
        box-shadow: 0 0 0 5000px rgba(255, 255, 255, .4);
    }
}

.share-gallery-modal {
    .qr-code {
        width: 100%;
    }
}

.qr-settings {
    .qr-preview-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: calc(var(--bs-border-radius) / 2);
        overflow: hidden;

        &:has(img[src]) {
            border: 1px solid var(--bs-border-color);
        }
    }

    .qr-code {
        max-width: 100%;
    }

    #qr_code_wrapper {
        aspect-ratio: 1;
    }
}

.view-message-modal {
    --bs-modal-width: 650px;

    .mp-text {
        border-bottom: 1px solid #eee;
    }

    .mp-label {
        font-weight: 700;
        display: inline-block;
        margin-right: 8px;
    }

    .mp-body {

        img,
        table {
            max-width: 100%;
        }
    }
}

.audio-modal-container {
    display: flex;
    gap: 1rem;

    .audio-sidebar {
        width: 400px;
        border-radius: var(--bs-border-radius);
        font-size: 12px;


    }

    .audio-main {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    @media (max-width: 768px) {
        flex-direction: column;

        .audio-sidebar {
            width: 100%;
            max-height: 150px;
            overflow-y: auto;
        }
    }
}
.social-links {
    margin-top: 48px;
    margin-bottom: 48px;
    max-width: 700px;

    a {
        color: var(--bs-body-color);
    }

    .social-link {
        width: 100%;
        display: flex;
        align-items: center;
        text-align: left;
        gap: 12px;
        text-decoration: none;
        line-height: 1.2em;
        
        @media(max-width: 640px) {
            flex-direction: column;
            text-align: center;
            max-width: 100px;
            font-size: .9em;
        }

        @media(max-width: 480px) {
            .social-link-label {
                // display: none;
            }
        }
    }
}

// .has-no-bg {
//     .social-links {
//         a {
//             color: var(--nmm-dark);
//         }
//     }
// }


.table-responsive {
    min-height: 300px;
    table-layout: fixed;

    tr {
        &.status-6 {
            th,
            td {
                background-color: var(--nmm-danger-light);
            }
        }
    }
}
.title-hero {
    min-height: 300px;
    // background-position: center;
    background-position: var(--focus-x) var(--focus-y);
    background-size: cover;
    background-repeat: no-repeat;

    &.has-image-bg,
    &.has-video-bg {
        .title-inner {
            margin: 0px -12px;
            background-image: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
        }

        .title-action-link {
            color: var(--white);
        }
    }

    @media(max-width: 640px) {
        min-height: 225px;
    }
}
.toast-wrapper {
  position: fixed;
  top: 16px;
  right: 16px;
  z-index: 1050;
  width: 300px;
}


.body-sql,
.body-debug {
  font-family: monospace;
  font-size: 12px;
}

.media-toolbox {
    display: none;
    position: fixed;
    top: 100px;
    right: 50px;
    border-width: var(--bs-border-width);
    box-shadow: var(--bs-box-shadow);
    z-index: 1050;
    width: 250px;
    background-color: var(--bs-body-bg);
    border-radius: var(--bs-border-radius);
    border-color: var(--bs-border-color-translucent);
    padding: 1rem;
}

.confirm-btn-group {
    // width: 100%;

    .delete-media {
        width: 100%;

        & .btn-outline-primary {
            border-left: 1px solid var(--bs-primary);
        }
    }
}


.delete-gallery {
  width: 100%;
}

.activate-gallery {
  width: 100%;
}

// Make sure the button group in the card-body takes up the full width
.card-body .btn-group {
  width: 100%;
  display: flex;
}

// Ensure the activate button takes up most of the space
.card-body .activate-gallery {
  flex-grow: 1;
}

.confirm-delete-media,
.confirm-delete-user,
.confirm-delete-template,
.confirm-delete-gallery,
.confirm-activate-gallery {
  padding: .375rem .75rem;
  width: 100px;

  &.hide {
    border-right: 0;
    border-left: 0;
    padding: .375rem 0;
    width: 0;
    font-size: 0;
    border-radius: 0;
    background-color: var(--bs-body-bg);
  }
}

.confirm-delete-media.hide ~ .delete-media,
.confirm-delete-user.hide ~ .delete-user,
.confirm-delete-template.hide ~ .delete-template,
.confirm-delete-gallery.hide ~ .delete-gallery,
.confirm-activate-gallery.hide ~ .activate-gallery {
  border-top-left-radius: var(--bs-border-radius);
  border-bottom-left-radius: var(--bs-border-radius);

}

body {
    font-family: var(--bg8-body);
    font-size: var(--bg8-base);
    line-height: 1.1em;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-family: var(--bg8-headings);
    font-weight: 100;
    text-transform: uppercase;
}

h1,
.h1 {
    font-size: var(--bg8-xxl);
}

h2,
.h2 {
    font-size: var(--bg8-xl);
}

h3,
.h3 {
    font-size: var(--bg8-l);
}

h4,
.h4 {
    font-size: var(--bg8-m);
}

h5,
.h5 {
    font-size: var(--bg8-s);
}

h6,
.h6 {
    font-size: var(--bg8-xs);
}

.preheader {
    text-transform: uppercase;
    font-size: var(--bg8-s);
}
.ar-4-3 {
  aspect-ratio: 4/3;
  object-fit: cover;
}

.ar-3-2 {
  aspect-ratio: 3/2;
  object-fit: cover;
}

.ar-16-9 {
  aspect-ratio: 16/9;
  object-fit: cover;
}

.ar-21-9 {
  aspect-ratio: 21/9;
  object-fit: cover;
}

.ar-1-1 {
  aspect-ratio: 1/1;
  object-fit: cover;
}

.bg-nmm-light {
  background-color: var(--nmm-light) !important;
}

.bg-nmm-dark {
  background-color: var(--nmm-dark) !important;
}

.c-nmm-light {
  color: var(--nmm-light) !important;
}

.c-nmm-dark {
  color: var(--nmm-dark) !important;
}

svg {
  fill: currentColor;
}

@media (max-width: 480px) {
  .hide-mobile {
    display: none;
  }
}

.has-image-bg,
.has-video-bg {
  color: var(--white);

  .bg-body-tertiary,
  .card-body {
    color: var(--black);
  }
}
.has-video-bg {
    position: relative;
    width: 100%;
    overflow: hidden;

    video {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-width: 100%;
        min-height: 100%;
        width: 100%;
        object-fit: cover;
        z-index: 1;
    }

    .card-img-overlay {
        z-index: 2;
    }

    .card-body {
        background-color: var(--bs-body-bg);
        z-index: 2;
    }

    .title-inner,
    .landing-wrapper-inner {
        z-index: 2;
        position: absolute;
        width: 100%;
    }
}